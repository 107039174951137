import Glide from '@glidejs/glide';

function initTiledCarousel(el: HTMLElement) {
  const glide = new Glide(el, {
    type: 'carousel',
    perView: 3,
    gap: 24,
    breakpoints: {
      1024: {
        perView: 2,
      },
      768: {
        perView: 1,
      },
    },
  });
  glide.mount();
}

const tiledCarousels: HTMLElement[] = Array.from(
  document.querySelectorAll('.js-tiled-carousel'),
);
tiledCarousels.forEach(initTiledCarousel);
